class TokenUtil {
    // 存储Token的键名
    static accessTokenKey = 'token';

    // 获取访问Token
    static getAccessToken() {
        return localStorage.getItem(TokenUtil.accessTokenKey);
    }

    // 设置访问Token
    static setAccessToken(accessToken) {
        localStorage.setItem(TokenUtil.accessTokenKey, accessToken);
    }

    // 移除访问Token
    static removeAccessToken() {
        localStorage.removeItem(TokenUtil.accessTokenKey);
    }


    // 移除所有Token
    static clearAllTokens() {
        localStorage.removeItem(TokenUtil.accessTokenKey);
    }
}

export default TokenUtil;
