import {createRouter, createWebHistory} from 'vue-router'
import BaseLayout from '@/views/Layout.vue'

const routes = [
    {
        path:
            '/',
        component: BaseLayout,
        children: [
            {
                path: 'patient',
                name: 'patient',
                component: () => import('../views/patient.vue')
            },
            {
                path: '',
                name: 'User',
                component: () => import('../views/User.vue')
            },
            {
                path: 'user',
                name: 'User',
                component: () => import('../views/User.vue')
            },
            {
                path: 'appointment',
                name: 'appointment',
                component: () => import('../views/Appointment.vue')
            },
            {
                path: 'feedback',
                name: 'feedback',
                component: () => import('../views/Feedback.vue')
            },
            {
                path: 'coupons',
                name: 'coupons',
                component: () => import('../views/Coupons.vue')
            },
            {
                path: 'notice',
                name: 'notice',
                component: () => import('../views/Notice.vue')
            },
            {
                path: 'institution',
                name: 'institution',
                component: () => import('../views/Institution.vue')
            },
            {
                path: 'device',
                name: 'device',
                component: () => import('../views/device.vue')
            },
            {
                path: 'other',
                name: 'other',
                component: () => import('../views/Other.vue')
            },
            {
                path: 'timePeriod',
                name: 'timePeriod',
                component: () => import('../views/TimePeriod.vue')
            },
            {
                path: 'refundRecord',
                name: 'refundRecord',
                component: () => import('../views/refundRecord/refundRecord.vue')
            },
            {
                path: 'appointmentstatistics',
                name: 'appointmentstatistics',
                component: () => import('../views/Appointment/AppointmentStatistics.vue')
            },
            {
                path: 'scanQRCodeRecord',
                name: 'scanQRCodeRecord',
                component: () => import('../views/ScanQRCodeRecord/ScanQRCodeRecord.vue')
            },
            // 其他子路由...
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
