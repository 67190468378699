import {createApp} from 'vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'dayjs/locale/zh-cn';
import locale from 'element-plus/es/locale/lang/zh-cn';
import App from './App.vue'

const app = createApp(App).use(ElementPlus, {locale});

app.use(ElementPlus)
app.use(router)
app.mount('#app')
